import React from "react";
import "../css/Myntra.css";
import "../calculator/Flipkart.css";
import * as XLSX from "xlsx/xlsx.mjs";
import template from "../template/Template_Myntra_calculator.json";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import Papa from "papaparse";
// import commision from "./myntraforwardcomponents/commision.json";
// import forwardfees from "./myntraforwardcomponents/forwardfees.json";
// import reversefees from "./myntraforwardcomponents/reversefees.json";

const Myntraforward = () => {
  const [formData, setFormData] = useState({
    data0: "",
    data1: "",
    data2: "",
    data3: "",
    dataB: "",
    dataG: "",
    dataD: "",
    ASP: 0,
    ASP0: "",
    dataC: "",
    zlc: true,
    xcv: true,
    pv: "",
    L: "",
    commisionF: 0,
    commissionV: 0,
    fixedfeesF: 0,
    paymentFeesF: 0,
    forwardgetF: 0,
    forwardfeesF: 0,
    reverseChargesF: 0,
    taxF: 0,
    I: 0,
  });
  const [commissionM, setCommissionM] = useState([]);
  const [forwardfeesM, setForwardfeesM] = useState([]);
  const [reversefeesM, setReversefeesM] = useState([]);
  const [fixedfeesM, setFixedfeesM] = useState([]);
  const [levelS, setLevelS] = useState();
  const [data2, setData2] = useState([]);
  const [errorContent, setErrorContent] = useState([]);
  const [input0, setInput0] = useState();
  const [input1, setInput1] = useState();
  const [input2, setInput2] = useState();
  const [error, setError] = useState(false);
  const [jsonOutput, setJsonOutput] = useState([]);
  const [jsonData, setJsonData] = useState([]);
  const [newJD, setNewJD] = useState([]);
  const [sales, setSales] = useState([]);
  const [returns, setReturns] = useState([]);
  const [highlight2, setHighlight2] = useState([]);
  const [highlight3, setHighlight3] = useState([]);
  const [sheetnames, setSheetnames] = useState([]);

  console.log(sales);
  console.log(returns);

  const level = [];
  const mrp = [];
  const discount = [];

  const [pv, setPv] = useState();
  const [isDisabled, setIsDisabled] = useState(false);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    /////////////////////////////////////////////

    const M = jsonData.map((item) => {
      const taxable_value =
        item[" Total DP "] -
        (item[" CGST -Amount "] +
          item[" SGST - Amount "] +
          item[" UTGST - Amount "] +
          item[" IGST -Amount "]);
      console.log(taxable_value);
      return {
        OrderDate: item["OrderDate"],
        InvoiceNo: item[" InvoiceNo"] === "" ? undefined : item[" InvoiceNo"],
        MagentoOrderNo: item["MagentoOrderNo"],
        OrderNo: item["OrderNo"],
        SKUCode: item["SKUCode"],
        "HSN code": item["HSN code"],
        "Invoice Amount": item[" Total DP "],
        "Taxable value": taxable_value,
        "CGST -Amount": item[" CGST -Amount "],
        "SGST - Amount": item[" SGST - Amount "],
        "UTGST - Amount": item[" UTGST - Amount "],
        "IGST -Amount": item[" IGST -Amount "],
        "Final Status": item["Final Status"],
      };
    });

    console.log(M);
    const a001 = M.filter((item) => {
      return item["InvoiceNo"] !== undefined;
    });
    console.log(a001);
    const a002 = a001.filter((item) => {
      return (
        item["Final Status"] === "Customer Returns" ||
        item["Final Status"] === "RTO"
      );
    });

    const a003 = a001.map((item, index) => {
      const { "Final Status": _, ...restOfPropeties } = item;
      return restOfPropeties;
    });
    console.log(a003);
    const a004 = a002.map((item, index) => {
      const { "Final Status": _, ...rest } = item;
      return rest;
    });
    a003.forEach((item, index) => {
      if (item["Taxable value"] < 100 && item["Taxable value"] !== 0) {
        setError(true);
        setHighlight2((prev) => [...prev, index]);
      }
    });
    a004.forEach((item, index) => {
      if (item["Taxable value"] < 100 && item["Taxable value"] !== 0) {
        setError(true);
        setHighlight3((prev) => [...prev, index]);
      }
    });

    setReturns(a004);
    console.log(a001);
    console.log(a002);
    console.log(a003);
    setSales(a003);
  };

  ///////////////////////////////////////////////////////////////////////////////////////////
  const downloadExcel = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet1 = workbook.addWorksheet("sales");
    const worksheet2 = workbook.addWorksheet("Returns");

    // Add 1 file header row
    const headerRow1 = worksheet1.addRow(Object.keys(sales[0]));
    headerRow1.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFb0c996" }, // Yellow background
      };
    });

    // Add 2 file header row
    const headerRow2 = worksheet2.addRow(Object.keys(returns[0]));
    headerRow2.eachCell((cell) => {
      cell.font = { bold: true }; // Make header bold
      cell.fill = {
        // Set fill color for header
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFb0c996" }, // Yellow background
      };
    });

    // Add 1 file data rows
    sales.forEach((data, index) => {
      if (highlight2.includes(index)) {
        // Check if index is in the highlight array
        const headerRow2 = worksheet1.addRow(Object.values(data));
        headerRow2.eachCell((cell) => {
          cell.font = { bold: true }; // Make header bold
          cell.fill = {
            // Set fill color for header
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF00" }, // Yellow background
          };
        });
      } else {
        worksheet1.addRow(Object.values(data)); // Add regular data row
      }
    });

    // Add 2 file data rows
    returns.forEach((data, index) => {
      if (highlight3.includes(index)) {
        // Check if index is in the highlight array
        const headerRow2 = worksheet2.addRow(Object.values(data));
        headerRow2.eachCell((cell) => {
          cell.font = { bold: true }; // Make header bold
          cell.fill = {
            // Set fill color for header
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFFF00" }, // Yellow background
          };
        });
      } else {
        worksheet2.addRow(Object.values(data)); // Add regular data row
      }
    });

    // Set the response type for file download
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `Nykaa_GST_Report_${input2}.xlsx`);
  };

  const downloadExcelError = () => {
    const worksheet = XLSX.utils.json_to_sheet(errorContent);
    // console.log(worksheet)
    const workbook = XLSX.utils.book_new();
    // console.log(workbook)

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "Error-file.xlsx");
  };

  // const handleFileChange = (e) => {
  //   const reader = new FileReader();

  //   // Ensure at least one file is selected
  //   if (e.target.files.length < 1) {
  //     console.error("Please select a file.");
  //     return;
  //   }

  //   // Read the first file
  //   reader.readAsBinaryString(e.target.files[0]);

  //   reader.onload = (e) => {
  //     const data = e.target.result;
  //     const workbook = XLSX.read(data, { type: "binary" });
  //     console.log(workbook);

  //     // Ensure that there are at least two sheets in the workbook
  //     //   if (workbook.SheetNames.length < 2) {
  //     //     console.error("The selected file does not contain a second sheet.");
  //     //     return;
  //     //   }

  //     // Access the second sheet (Sheet2)
  //     const sheetName = workbook.SheetNames[0];
  //     console.log(sheetName); // Get the second sheet name
  //     const sheet = workbook.Sheets[sheetName]; // Get the second sheet
  //     console.log(sheet);
  //     const parsedData = XLSX.utils.sheet_to_json(sheet); // Parse the data

  //     // Set the parsed data to state
  //     setJsonData((item) => [...item, ...parsedData]);
  //     // Access the second sheet (Sheet2)
  //     const sheetName1 = workbook.SheetNames[1];
  //     const sheet1 = workbook.Sheets[sheetName1]; // Get the second sheet
  //     const parsedData1 = XLSX.utils.sheet_to_json(sheet1); // Parse the data
  //     console.log(parsedData1);
  //     // Set the parsed data to state
  //     setJsonData((item) => [...item, ...parsedData1]);
  //   };
  // };
  // useEffect(()=>{

  const handleFileChange = (e) => {
    const reader = new FileReader();
    reader.readAsBinaryString(e.target.files[0]);

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      console.log(workbook.SheetNames);
      const findSheet = workbook.SheetNames.find((element) =>
        element.includes(input1)
      );
      console.log(findSheet);
      const sheet = workbook.Sheets[findSheet];

      const parsedData = XLSX.utils.sheet_to_json(sheet);

      // Convert Excel date serial numbers to formatted date strings
      const formattedData = parsedData.map((row) => {
        // Specifically convert Payment_Date if it exists
        if (row.OrderDate && typeof row.OrderDate === "number") {
          row.OrderDate = excelSerialToFormattedDate(row.OrderDate);
        }
        return row;
      });
      setJsonData((item) => [...item, ...formattedData]);
      // const sheetName2 = workbook.SheetNames[1];
      // const sheet2 = workbook.Sheets[sheetName2];
      // const parsedData2 = XLSX.utils.sheet_to_json(sheet2);

      // // Convert Excel date serial numbers to formatted date strings
      // const formattedData2 = parsedData2.map((row) => {
      //   // Specifically convert Payment_Date if it exists
      //   if (row.OrderDate && typeof row.OrderDate === "number") {
      //     row.OrderDate = excelSerialToFormattedDate(row.OrderDate);
      //   }
      //   return row;
      // });
      // setJsonData((item) => [...item, ...formattedData2]);
    };
  };

  // Function to convert Excel serial date to formatted date string
  function excelSerialToFormattedDate(serial) {
    // Handle potential Excel date serialization quirks
    if (serial < 1) return "";

    const excelEpoch = new Date(1900, 0, 1); // January is 0 in JavaScript Date

    // Adjust for Excel's leap year bug
    const adjustedSerial = serial > 59 ? serial - 1 : serial;

    const date = new Date(
      excelEpoch.getTime() + (adjustedSerial - 1) * 24 * 60 * 60 * 1000
    );

    return `${("0" + date.getDate()).slice(-2)}-${(
      "0" +
      (date.getMonth() + 1)
    ).slice(-2)}-${date.getFullYear()}`;
  }

  // },[input2])
  //////////////////////////////////////////////////////////////////////////////////
  console.log(jsonData);
  console.log(sales);
  console.log(returns);
  return (
    <div className="main-div padding-top">
      <div className="sub-heading">
        <h3>
          <b>Nykaa GST Reports</b>
        </h3>
      </div>
      <div className="sub-heading">
        <h3 className="subHead">Sales & Returns</h3>
      </div>

      <section class="u-section-container">
        <div>
          <input
            className="inputtext"
            type="text"
            placeholder="ENTER FILE MONTH"
            onChange={(e) => setInput1(e.target.value)}
          />
        </div>

        <p className="paragraphTitle">Sales report file :</p>

        <div>
          <input
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileChange}
            multiple
          />

          {/* <div
            data-index="0"
            class="u-tabs-tab u-tabs-secondary u-tabs-active"
            onClick={downloadTemplate}
          >
            Download Template
          </div> */}
        </div>
        {/* <label for="pet-select">Choose a pet:</label> */}
        <div>
          {/* <select id="pet-select" onClick={(e) => setInput0(e.target.value)}>
            <option value="">--Please choose a month--</option>
            <option value="Jan">Jan</option>
            <option value="Feb">Feb</option>
            <option value="Mar">Mar</option>
            <option value="Apr">Apr</option>
            <option value="May">May</option>
            <option value="Jun">Jun</option>
            <option value="Jul">Jul</option>
            <option value="Aug">Aug</option>
            <option value="Sep">Sep</option>
            <option value="Oct">Oct</option>
            <option value="Nov">Nov</option>
            <option value="Dec">Dec</option>
          </select> */}
          {/* <input
            className="inputtext"
            type="text"
            placeholder="Year"
            onChange={(e) => setInput1(e.target.value)}
          /> */}
          <input
            className="inputtext"
            type="text"
            placeholder="ENTER MONTH"
            onChange={(e) => setInput2(e.target.value)}
          />
        </div>
      </section>
      <div>
        <div class="p-SellerSettlements-module-css-section">
          <div style={{ display: "contents" }}>
            <div class="p-SellerSettlements-module-css-title">
              <div
                class="u-layout-stack u-layout-medium"
                style={{
                  "align-items": "center",
                  "justify-content": "flex-start",
                  "flex-wrap": "nowrap",
                }}
              >
                {/* <h3 class="u-text-h3 u-text-dark u-text-high u-text-bolder display-initial">
                  GST Report :
                </h3> */}
              </div>
            </div>
            <div class="p-SellerSettlements-module-css-report">
              <div class="u-tabs-group">
                <div class="u-tabs-pane secondary">
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={handleFormSubmit}
                  >
                    Calculate
                  </div>
                  <div
                    data-index="0"
                    class="u-tabs-tab u-tabs-secondary u-tabs-active"
                    onClick={downloadExcel}
                  >
                    Download Result
                  </div>
                  {errorContent.length > 0 ? (
                    <div
                      data-index="0"
                      class="u-tabs-tab u-tabs-secondary u-tabs-active"
                      onClick={downloadExcelError}
                    >
                      Download Error Data
                    </div>
                  ) : null}
                  {error ? (
                    <div>
                      <h3 className="errorfor<200">
                        itemTaxableAmount lesser than 100
                      </h3>
                    </div>
                  ) : null}
                </div>

                <div class="u-table-simple">
                  <div class="u-table-container">
                    <table class="u-table-table">
                      {data2.length > 0 && (
                        <thead>
                          <tr>
                            {Object.keys(data2[0]).map((value, index) => (
                              <th
                                rowspan="1"
                                colspan="1"
                                class=""
                                style={{
                                  "--sticky-top-offset":
                                    "calc(0 * var(--table-head-height))",
                                  "--sticky-left-offset": "unset",
                                  width: "auto",
                                  "text-align": "start",
                                }}
                                key={index}
                              >
                                {value}
                              </th>
                            ))}

                            {/* <th
                            rowspan="1"
                            colspan="1"
                            class=""
                            style={{"--sticky-top-offset": "calc(0 * var(--table-head-height))", "--sticky-left-offset": "unset", "width": "auto", "text-align": "start"}}
                          >
                            Documents
                          </th> */}
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {data2.map((value, index) => (
                          <tr>
                            {Object.values(value).map((value, index) => (
                              <td
                                class=""
                                style={{
                                  "--sticky-left-offset": "unset",
                                  width: "auto",
                                  "text-align": "start",
                                }}
                                key={index}
                              >
                                {value}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Myntraforward;
